import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
//import CopyBlock from "../../components/Copy/CopyBlock"
//import InfoBar from '../../components/Utilities/InfoBar'

class GamesVsEachOther extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    // let [{
    //   freeKicks: mElFreeKicks,
    //   goals: mElGoals,
    //   assists: mElAssists,
    //   hatTricks: mElHatTricks,
    //   minsPerGoal: mElMinsPerGoal,
    //   minsPerGoalContribution: mElMinsPerGoalCont,
    //   pens: mElPens,
    //   apps: mElApps,
    // }] = mdata.filter(d => d.node.competition === "El Clasico").map(d => d.node)
  
    // let [{
    //   freeKicks: rElFreeKicks,
    //   goals: rElGoals,
    //   assists: rElAssists,
    //   hatTricks: rElHatTricks,
    //   minsPerGoal: rElMinsPerGoal,
    //   minsPerGoalContribution: rElMinsPerGoalCont,
    //   pens: rElPens,
    //   apps: rElApps,
    // }] = rdata.filter(d => d.node.competition === "El Clasico").map(d => d.node)

    return (

      <Layout>
        <SEO
          title={"Messi vs Ronaldo Goals and Stats in Games vs Each Other"}
          description={`Messi and Ronaldo have faced each other over 30 times throughout their careers, but who has scored more goals and made more assists in these contests?`}
          canonicalPath={`/all-time-stats/games-vs-each-other/`}
        />

        <h1>
          <span className="sr-only">Messi vs Ronaldo Goals and Stats in</span> Games vs Each Other
        </h1>

        

        <CompetitionBlock competition="All Games vs Each Other" type="all" mdata={mdata} rdata={rdata} subtitle="All Competitions" perfmsg="[comps] since [season]" />

        <CompetitionBlock competition="H2H in Finals" type="all" mdata={mdata} rdata={rdata} subtitle="Games vs each other in finals" perfmsg="[comps] since [season]" />

        <CompetitionBlock competition="H2H La Liga" type="club" mdata={mdata} rdata={rdata} subtitle="Games vs each other in La Liga" />

        <CompetitionBlock competition="H2H Champions League" type="club" mdata={mdata} rdata={rdata} subtitle="Games vs each other in Champions League" perfmsg="Since [season]" />

        <CompetitionBlock competition="H2H Copa del Rey" type="club" mdata={mdata} rdata={rdata} subtitle="Games vs each other in Copa del Rey" />

        <CompetitionBlock competition="H2H Spanish Super Cup" type="club" mdata={mdata} rdata={rdata} subtitle="Games vs each other in Spanish Super Cup" />

        <CompetitionBlock competition="H2H International" type="int" mdata={mdata} rdata={rdata} subtitle="Games vs each other in internationals" />

        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />
          
          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/h2h/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/h2h/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
  }
`

export default GamesVsEachOther
